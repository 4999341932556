.contact-details-holder{
	width: 200px;
	float: left;
	
	.contact-details{
		width: 200px;
		float: left;
	}
	
	.address{
		float: left;
		width: 200px;
	}
	
	@include bp($mobile_wide){
		width: 100%;
		float: none;
		
	}	
}

.map{
	width: 100%;
	height: 100%;
	/*overflow: hidden;
	height: 300px;
	float: left;
	background: $grey-light;*/
}

.map-holder{
	float: left;
	margin: 35px 0 0 0;
	height: 200px;
}
