.card{
	width: 300px;
	min-height: 250px;
	border-radius: 10px;
	background: $grey-light;
	margin: 20px 20px 0 0;
	/*margin: 0 0 20px 0;*/
	float: left;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 10px;
	display: inline-block;

  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  column-break-inside: avoid;
	
	.card-image-holder{
		width: 280px;
		position: relative;

		.car-image{
			width: 100%;
			height: auto;
		}
		.sold-image{
			display: none; 
			position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    max-height: 158px;
	    pointer-events: none;
		}
	}
	
	&:nth-child(4n+4){
		/*margin: 20px 0 0 0; */
	}
	
	/*@include bp('(max-width: 1319px)'){
		&:nth-child(4n+4){
			margin: 20px 20px 0 0; 
		}
		&:nth-child(3n+3){
			margin: 20px 0 0 0; 
		}
	}
	
	@include bp('(max-width: 999px)'){
		&:nth-child(3n+3){
			margin: 20px 20px 0 0; 
		}
		&:nth-child(2n+2){
			margin: 20px 0 0 0; 
		}
	}
	@include bp('(max-width: 679px)'){
		margin: 20px 0 0 0; 
	}*/
	
	/*@include bp('(max-width: 979px)'){
		margin: 20px 0 0 0;
	}*/
	
	.card-name{
		color: $grey-dark;
		font-size: 20px;
		font-weight: normal;
		line-height: 20px;
		margin: 10px 0 0 0;
	}
	
	.card-price{
		color: $grey-dark;
		font-size: 14px;
		margin: 0;
	}
	
}

  
.columns{
	box-sizing: border-box;
	width: 99%;
	margin: 0 auto;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /*-webkit-columns: 5 300px;
  -moz-columns: 5 300px;
  columns: 5 300px;*/

  .card{
  	float: none;
  	display: inline-block;

  }

}
