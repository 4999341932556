input[type='text'], input[type='password'], textarea{
    width: 200px;
    float: left;
    clear: left;
    box-sizing: border-box;
    margin: 0 0 5px 0;
    &.error{
        border: 1px solid $red;
    }
}

label{
    display: block;
    float: left;
    clear: left;
    margin: 10px 0;
}

input[type='checkbox']+label{
    float:left;
    clear:left;
}
input[type='checkbox']{
    margin: 0 5px 0 0 ;
}

button{
    float: left;
    clear: left;
    box-sizing: border-box;
    padding: 10px;
    min-width: 100px;
    font-size: 25px;
}

.formHolder{
    margin: 50px auto;
    width: 230px;
}

.required{
    float: left;
    margin: 7px 0 0 5px;
    font-size: 20px;
    color: $red;
}
.info-required{
    color: $red;
}