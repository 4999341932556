header{
	width: 100%;
	height: 125px;
	box-sizing: border-box;
	padding: 0 20px;
	clear: right;
	/*float: left;*/
	/*position: fixed;*/
	
	.logo{
		width: 190px;
		height: 70px;
		float: left;
		margin: 20px 0 0 0;
		@include bp($mobile){
			width: 150px;
			height: 55px;
			margin: 10px auto 0 auto;
			float: none;
		}
	}
	
	.header-contact{
		font-family: 'Calibri', Arial, Helvetica, sans-serif;
		font-size: 12px;
		line-height: 20px;
		float: right;
		margin: 20px 0px 0 0;
		@include bp($mobile){
			display: block;
			margin: 10px auto 0 auto;
			float: none;
			ul{
				margin: 0;
				padding: 0;
			}
			li{
				width: 130px;
				margin: 0 auto;
				display: block;
				
				/*float: left;
				margin: 0 0 0 10px;*/
			}
		}
	}
	
	nav{
		border-top: 1px solid $grey-light;
		border-bottom: 1px solid $grey-light;
		float: left;
		width: 100%;
		margin: 10px 0 0 0;
		height: 30px;
		display: block;
		padding: 0;
		ul{
			padding: 0;
		}
		
		li{
			margin: 0 0 0 5px;
			float: left;
			font-size: 20px;
			line-height: 30px;
			background: url('../images/star-seperator.png') no-repeat top left;
			padding-left: 15px;
			
			&:first-child{
				background: none;
				padding-left: 0;
				margin: 0;
			}
			
		}
		a{
			
			&.active{
				color: $red;
			}
		}
	}
}
