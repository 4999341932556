#home{
	
	.featured-cars{
		width: 980px;
		margin: 10px auto 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
		
		@include bp('(max-width: 1040px)'){
			width: 300px;
		}
	}
}
