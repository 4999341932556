.view-car{
    width: 100%;
    max-width: 1020px;
    // margin: 0 auto;

    .carousel-cell{
        width: 100%;
        max-width:480px; 
        height: 300px; 
        overflow: hidden;
        text-align: center; 
        /*border-radius: 20px;*/
        img{
            /*width: 100%;*/
            max-height: 300px;
            max-width: 480px;
            border-radius: 20px;
        }
    }
    .thumbs{

        .thumb{
            box-sizing: border-box;
            float: left;
            width: 80px;   
            height: 50px;
            overflow: hidden;
            margin: 5px 5px 0 0;
            border-radius: 10px;
            &.is-selected{
                border: 2px solid $red;
            }
            img{
                width: 100%;
            }
        }
    }
    .images{
        width: 100%;
        max-width: 500px;
        float: left;
        background: $grey-light;
        box-sizing: border-box;
        padding: 10px;

        .carImage{
            max-width: 150px;
            float: left;
            margin: 0 0 0 5px;
            border: none;
            overflow: hidden;
            border-radius: 10px;
            position: relative;
            /*pointer-events: none;*/
            img{
                width: 100%;
            }
            .btn-delete{
                pointer-events: all;
                position: absolute;
                left: 50%;
                margin-left: -40px;
                bottom: 10px;
                opacity: 0;
                transition: all 0.4s;
                /*display: none;*/
            }

            &:hover{
                .btn-delete{
                    opacity: 1;
                }
            }
        }
    }

    #like-holder{
        display: block;
        width: 100%;
        max-width: 500px;
        height: 30px;
        float: left;
        clear: both;
        margin: 10px 0 0 0;
    }
    
    .view-left{
        float: left;
        width: 100%;
        max-width: 500px;
    }
    .details{
        width: 100%;
        max-width: 500px;
        float: left;
        margin : 0 0 0 20px;
        font-size: 18px;
        .soldImage{
            display: none;
            background: url('../images/sold.png') no-repeat;
            width: 100%;
            height: 150px;
            position: relative;
        }
        &.sold{
            .detail{
                opacity: 0.6;
            }
            .soldImage{
                display: block;
            }
        }
        
        @include bp('(max-width: 1019px)'){
            margin: 10px 0 0 0;
        }
        
        .detail{
            float: left;
            width: 100%;
            margin: 5px 0 5px 0;
            &.detail-50{
                width: 50%;
            }
        }
        .field-label{
            width: 100px;
            float: left;
            clear: left;
            
        }
        
        .field{
            float: left;
            max-width: 400px;
        }
        .btn-remove{
            clear: none;
            margin: 0 0 0 5px;
        }
    }

    .editable{
        min-width: 200px;
        border: 1px solid $red;
    }
}

.addbutton{
    position: relative;
    width: 80px;

/*    input[type='file']{
        position: absolute;
    }*/

}

.addbutton-label{
    position: absolute;
    top:0;
    width: 70px;
    text-align: center;
    color: white;
    pointer-events: none;
    line-height: 30px;
}

/*@import '../../node_modules/dropzone/dist/dropzone.css'; */