#all-cars{
	
	.cars{
		width: 100%;
	    max-width: 1280px;
	    margin: 10px auto 0 auto;
		
		@include bp('(max-width: 1319px)'){
			max-width: 960px;
			margin: 10px auto 0 auto;
		}
		
		@include bp('(max-width: 999px)'){
			max-width: 640px;
			margin: 10px auto 0 auto;
		}
		
		@include bp('(max-width: 679px)'){
			max-width: 300px;
			margin: 10px auto 0 auto;
		}
	}
}
